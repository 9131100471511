import React from 'react';

const ScoreBar = ({ score }) => {
  const maxScore = 32;
  const minScore = 8;
  const barPercentage = ((score - minScore) / (maxScore - minScore)) * 100;

  return (
    <div className="relative w-full bg-gray-200 rounded-full h-6 overflow-hidden">
      <div
        className="absolute h-full bg-gray-200 right-0"
        style={{ width: `${100 - barPercentage}%` }}
      />
      <div
        className="h-full"
        style={{
          width: '100%',
          background: `linear-gradient(to right, green 0%, yellow 50%, red 100%)`,
        }}
      />
    </div>
  );
};

export default ScoreBar;