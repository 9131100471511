import React, {useState} from 'react';
import './index.css';
import ScoreBar from "./ScoreBar";
import Footer from "./Footer";

// @formatter:off
const questions = [
  {id: 1, text: 'Zawsze odnajduję nowe i interesujące aspekty mojej pracy', type: "disengagement", reversed: false},
  {id: 2, text: 'Są dni, kiedy już przed pracą czuję się zmęczony(a)', type: "exhaustion", reversed: true},
  {id: 3, text: 'Coraz częściej zdarza się, że mówię o mojej pracy w negatywny sposób', type: "disengagement", reversed: true},
  {id: 4, text: 'Po pracy zwykle potrzebuję więcej czasu niż kiedyś, aby się zrelaksować i wrócić do formy', type: "exhaustion", reversed: true},
  {id: 5, text: 'Dość dobrze znoszę presję związaną z moją pracą ', type: "exhaustion", reversed: false},
  {id: 6, text: 'Ostatnio zwykle mniej myślę w pracy, a moją pracę wykonuję prawie mechanicznie', type: "disengagement", reversed: true},
  {id: 7, text: 'Moja praca stwarza wiele pozytywnych wyzwań', type: "disengagement", reversed: false},
  {id: 8, text: 'W czasie pracy coraz częściej czuję się emocjonalnie wyczerpany(a)', type: "exhaustion", reversed: true},
  {id: 9, text: 'Z czasem można stracić zaangażowanie w wykonywanie tego typu pracy', type: "disengagement", reversed: true},
  {id: 10, text: 'Po pracy z reguły mam dość siły, aby zająć się czymś dla własnej przyjemności', type: "exhaustion", reversed: false},
  {id: 11, text: 'Czasami to, co robię w pracy, napawa mnie obrzydzeniem', type: "disengagement", reversed: true},
  {id: 12, text: 'Po pracy zazwyczaj czuję się wykończony(a) i znużony(a)', type: "exhaustion", reversed: true},
  {id: 13, text: 'To jedyny typ pracy, jaki wyobrażam sobie, że mogę wykonywać', type: "disengagement", reversed: false},
  {id: 14, text: 'Zazwyczaj dobrze daję sobie radę z ilością pracy, jaką mam wykonać', type: "exhaustion", reversed: false},
  {id: 15, text: 'Z czasem coraz bardziej angażuję się w swoją pracę', type: "disengagement", reversed: false},
  {id: 16, text: 'Kiedy pracuję, zazwyczaj czuję się pełny(a) energii', type: "exhaustion", reversed: false},
  // translations from: https://medpr.imp.lodz.pl/pdf-60320-4168?filename=Psychometryczne.pdf
];
// @formatter:on

const options = [
  {text: "Zdecydowanie się zgadzam", score: 1},
  {text: "Zgadzam się", score: 2},
  {text: "Nie zgadzam się", score: 3},
  {text: "Zdecydowanie się nie zgadzam", score: 4}
]

const App = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [scores, setScores] = useState(Array(questions.length).fill(null));
  const [selectedAnswers, setSelectedAnswers] = useState(Array(questions.length).fill(null));
  const [showResults, setShowResults] = useState(false);

  const handleOptionSelect = (currentIndex, optionIndex) => {
    const {reversed} = questions[currentIndex];
    const score = reversed ? 5 - options[optionIndex].score : options[optionIndex].score;
    const updatedScores = [...scores];
    updatedScores[currentIndex] = score;
    setScores(updatedScores);

    const updatedSelectedAnswers = [...selectedAnswers];
    updatedSelectedAnswers[currentIndex] = optionIndex;
    setSelectedAnswers(updatedSelectedAnswers);
  };

  const goToNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex >= 1) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const exhaustionScore = scores.filter((s, i) => questions[i].type === "exhaustion")
    .reduce((acc, score) => acc + (score || 0), 0);
  const disengagementScore = scores.filter((s, i) => questions[i].type === "disengagement")
    .reduce((acc, score) => acc + (score || 0), 0);


  return (
    <>
      <div className="flex flex-col items-center justify-center h-screen bg-background font-serif">
        <div className="pb-8">
          <h1 className="text-4xl font-bold mb-4 text-mainFont text-center">Darmowy test wypalenia zawodowego</h1>
          <h2 className="text-2xl font-bold mb-4 text-mainFont text-center">metodą Oldenburg Burnout Inventory</h2>
        </div>
        <div className="bg-white p-8 rounded shadow-lg text-center max-w-2xl mx-auto">
          {!showResults ? (
            <>
              <div className="mb-6">
                <h2 className="text-2xl font-bold mb-4 text-mainFont text-left">
                  {currentQuestionIndex + 1}. {questions[currentQuestionIndex].text}
                </h2>
              </div>
              <div className="space-y-3">
                {options.map((option, index) => (
                  <button
                    key={option.text}
                    onClick={() => handleOptionSelect(currentQuestionIndex, index)}
                    className={`w-full px-4 py-2 rounded transition duration-300 ${
                      selectedAnswers[currentQuestionIndex] === index
                        ? "bg-selectedOptionButtonBackground text-selectedOptionButtonText"
                        : "bg-gray-300 text-optionButtonBackground hover:bg-optionButtonHover"
                    }`}
                  >
                    {option.text}
                  </button>
                ))}
              </div>
              <div className="flex justify-between mt-8 space-x-4">
                <button
                  onClick={goToPreviousQuestion}
                  className={`text-white px-6 py-2 rounded transition duration-300 ${
                    currentQuestionIndex === 0 ? "invisible" : "text-navButtonDisabledText bg-navButtonBackground hover:bg-navButtonHover"
                  }`}
                >
                  Poprzednie pytanie
                </button>
                <button
                  onClick={goToNextQuestion}
                  className={`px-6 py-2 rounded transition duration-300 ${
                    selectedAnswers[currentQuestionIndex] == null
                      ? "bg-navButtonDisabledBackground text-navButtonDisabledText opacity-50 cursor-not-allowed"
                      : currentQuestionIndex + 1 === questions.length ?
                        "bg-navButtonLastQuestionBackground text-white hover:bg-navButtonLastQuestionHover"
                        : "bg-navButtonBackground text-white hover:bg-navButtonHover"
                  }`}
                  disabled={selectedAnswers[currentQuestionIndex] == null}
                >
                  {currentQuestionIndex + 1 === questions.length ? "Koniec testu" : "Następne pytanie"}
                </button>
              </div>
            </>
          ) : (
            <div>
              <h2 className="text-2xl font-bold mb-4 text-mainFont">Twoje wyniki</h2>
              <div className="p-4">
                <h2 className="text-xl font-bold text-mainFont">
                  Współczynnik wyczerpania: {exhaustionScore}
                  <div className="mt-5 flex items-center justify-between">
                    <p className="text-sm whitespace-nowrap pr-2">Min: 8</p>
                    <ScoreBar score={exhaustionScore}/>
                    <p className="text-sm whitespace-nowrap pl-2">Max: 32</p>
                  </div>
                </h2>
                <h3 className="mt-2 text-mainFont">Wyczerpanie, oznaczające nie tylko spadek energii, ale również utratę
                  sił fizycznych, jak i uszczuplenie zasobów poznawczych.</h3>
              </div>
              <div className="p-4">
                <h2 className="text-xl font-bold text-mainFont">
                  Współczynnik wycofania: {disengagementScore}
                  <div className="mt-5 flex items-center justify-between">
                    <p className="text-sm whitespace-nowrap pr-2">Min: 8</p>
                    <ScoreBar score={disengagementScore}/>
                    <p className="text-sm whitespace-nowrap pl-2">Max: 32</p>
                  </div>
                </h2>
                <h3 className="mt-2 text-mainFont">opis...</h3>
              </div>
            </div>
          )}
          <div className="debug text-red-600 absolute top-0 left-0 m-4">
            <h2>debug:</h2>
            <h3>currentQuestionIndex={currentQuestionIndex}</h3>
            <h3>scores={scores.join(", ")}</h3>
            <h3>selectedAnswer={selectedAnswers.join(", ")}</h3>
            <h3>showResults={showResults}</h3>
            <h3>exhaustionScore={exhaustionScore} / 32</h3>
            <h3>disengagementScore={disengagementScore} / 32</h3>
            <ScoreBar score={8}/>
            <ScoreBar score={16}/>
            <ScoreBar score={20}/>
            <ScoreBar score={24}/>
            <ScoreBar score={32}/>
          </div>
        </div>

      </div>
      <Footer/>
    </>
  );
};

export default App;