import React from "react";
import GithubLogo from './github-logo.svg'
import InstagramLogo from './instagram-logo.svg'
import YoutubeLogo from './youtube-logo.svg'

const Footer = () => (
  <footer
    className="fixed inset-x-0 bottom-0 flex justify-between items-center py-4 px-2 md:px-32 lg:px-64 border-t border-gray-300 bg-transparent">
    <span className="text-xs text-gray-600">©2022-2024 <a href="https://www.instagram.com/drogaprogramisty/"
                                                          target="_blank"
                                                          rel="noopener noreferrer">Droga Programisty</a></span>
    <div className="flex space-x-4">
      <a href="https://github.com/lukaszdutka" target="_blank" rel="noopener noreferrer">
        <div className="w-[50px] h-[50px] flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300">
          <img className="w-[22px]" src={GithubLogo} alt="Github Logo"/>
        </div>
      </a>
      <a href="https://www.instagram.com/drogaprogramisty/" target="_blank" rel="noopener noreferrer">
        <div className="w-[50px] h-[50px] flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300">
          <img className="w-[22px]" src={InstagramLogo} alt="Instagram Logo"/>
        </div>
      </a>
      <a href="https://www.youtube.com/channel/UCam2b3Oss4FunEff9eFTStg" target="_blank" rel="noopener noreferrer">
        <div className="w-[50px] h-[50px] flex items-center justify-center rounded-full bg-gray-200 hover:bg-gray-300">
          <img className="w-[22px]" src={YoutubeLogo} alt="Youtube Logo"/>
        </div>
      </a>
    </div>
  </footer>
);

export default Footer;
